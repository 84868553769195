const BASE_URL = "http://localhost/sagas_react";

const API_ENDPOINTS = {
  USERS: "/users",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  POSTS: "/posts",
  TAGS: "/tags",
  MEDIA:"/media",
};

export { BASE_URL, API_ENDPOINTS };
