// PostLoader.js
import React from 'react';

const PostLoader = ({ loader }) => {
  const loaders = Array.from({ length: loader }, (_, index) => index + 1);

  return (
    <>
      {loaders.map((post_id) => (
        <div className="col-lg-6" key={`post-${post_id}`}>
  <article className="position-relative h-100">


    <div className="post-img position-relative overflow-hidden">
      <div className="skeleton skeleton-img"></div>
      <span className="skeleton skeleton-date"></span>
    </div>

    <div className="post-content d-flex flex-column">

      <h3 className="skeleton skeleton-title">Lorem Ipsum</h3>


      <div className="meta d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="skeleton skeleton-icon"></div>
          <span className="skeleton skeleton-text"></span>
        </div>
        <span className="px-3 text-black-50">/</span>
        <div className="d-flex align-items-center">
          <div className="skeleton skeleton-icon"></div>
          <span className="skeleton skeleton-text"></span>
        </div>
      </div>


      <p className="skeleton skeleton-paragraph"></p>

      <hr />

      <div className="skeleton skeleton-readmore"></div>

    </div>

  </article>
</div>
      ))}
    </>
  );
};

export default PostLoader;
