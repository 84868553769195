import React from 'react';
import { Link } from 'react-router-dom';
// import siteSettings from "../siteSettings.json"
// const credentials = btoa('admin:Alankar@123#');



const menuItems = [
  {
    id: 447,
    title: { rendered: "Stories", slug: "/" },
    object: "custom",
    parent: 0,

  },
  {
    id: 55,
    title: { rendered: "Ramayana", slug: "ramayana-epic" },
    object: "category",
    parent: 447,
  },
  {
    id: 54,
    title: { rendered: "Mahabharat", slug: "mahabharat-epic" },
    object: "category",
    parent: 447,
  },
  {
    id: 264,
    title: { rendered: "Short Stories", slug: "short-stories" },
    object: "category",
    parent: 447,
  },
  {
    id: 4291,
    title: { rendered: "Panchatantra", slug: "/category/panchatantra" },
    object: "category",
    parent: 0,
  },
  {
    id: 379,
    title: { rendered: "Blog", slug: "/blog" },
    object: "custom",
    parent: 0,
  },
  {
    id: 2326,
    title: { rendered: "Web Stories", slug: "/" },
    object: "custom",
    parent: 0,
  },
  {
    id: 53,
    title: { rendered: "About Us", slug: "/" },
    object: "page",
    parent: 0,
  },
  {
    id: 296,
    title: { rendered: "Contact Us", slug: "/" },
    object: "page",
    parent: 0,
  },
];
const NavBar = () => {

  // Function to build the hierarchical structure of the menu
  const buildMenuTree = (items) => {
    let menu = {};
    items.forEach(item => {
      if (item.parent === 0) {
        menu[item.id] = { ...item, children: [] };
      } else {
        const parent = menu[item.parent] || Object.values(menu).find(el => el.id === item.parent);
        if (parent) {
          parent.children.push(item);
        }
      }
    });
    return menu;
  };


  const menuTree = buildMenuTree(menuItems);

  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
    <div className="container position-relative d-flex align-items-center justify-content-between">
      <Link to="/" className="logo d-flex align-items-center me-auto me-xl-0">
  

        <h1 className="sitename">{process.env.REACT_APP_TITLE}</h1>
      </Link>

      <nav id="navmenu" className="navmenu">
        <ul>
        {Object.values(menuTree).map(parentItem => (
              <li key={parentItem.id} className="dropdown">
                {parentItem.children.length > 0 ? (
                  <>
                    <a className="nav-link dropdown-toggle" href="/" id={`navbarDropdown${parentItem.id}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {parentItem.title.rendered}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby={`navbarDropdown${parentItem.id}`}>
                      {parentItem.children.map(childItem => (
                        <li key={childItem.id}>
                          <Link
                            className="dropdown-item"
                            to={childItem.object === 'category' ? `/category/${childItem.title.slug}` : `${childItem.title.slug}`}
                          >
                            {childItem.title.rendered}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (

                  <Link
                    className="nav-link"
                    to={`${parentItem.title.slug}`}
                  >
                    {parentItem.title.rendered}
                  </Link>

                )}
              </li>
            ))}


          {/* <li><a href="index.html" className="active">Home</a></li>
          <li><a href="about.html">About</a></li>
          <li><a href="single-post.html">Single Post</a></li>
          <li className="dropdown"><a href="#"><span>Categories</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
            <ul>
              <li><a href="category.html">Category 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="#">Deep Dropdown 1</a></li>

                </ul>
              </li>
              <li><a href="category.html">Category 2</a></li>

            </ul>
          </li>
          <li><a href="contact.html">Contact</a></li> */}
        </ul>
        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>

      <div className="header-social-links">
        <a href="/" className="twitter"><i className="bi bi-twitter-x"></i></a>
        <a href="/" className="facebook"><i className="bi bi-facebook"></i></a>
        <a href="/" className="instagram"><i className="bi bi-instagram"></i></a>
        <a href="/" className="linkedin"><i className="bi bi-linkedin"></i></a>
      </div>

    </div>
  </header>
  );
};

export default NavBar;