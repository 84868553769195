import React from 'react'
import ShowImage from './ShowImage';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/dateUtils';

// import { fetchCategoryNames } from '../categoryUtils';




// const credentials = btoa('admin:Alankar@123#');
export default function PostLists(props) {

  let { title, slug, post_id, excerpt, media,categories,date,author } = props;

  // const [categoryNames,setCategoryNames] = useState([]);
  const formatExcerpt = (excerpt) => {
    return excerpt.replace(/\[&hellip;\]|\[…\]/g, '...');
  };
  // const navigate = useNavigate();




  // const handlePostClick = (slug) => {
  //   // Store post_id in local storage or session storage
  //   // localStorage.setItem('currentPostId', post.id);
  //   // setPostIDSinglePage(post_id,media)
  //   // Navigate to the post URL using slug without showing post_id in the URL
  //   if (post_type === "blogs") {
  //     navigate(`/blog/${slug}`);
  //   } else {
  //     navigate(`/${slug}`);
  //   }

  // };
  return (
    <div className="col-lg-6" id={post_id} key={`post-${post_id}`}>
      <article className="position-relative h-100">

        <div className="post-img position-relative overflow-hidden">
          <ShowImage media_id={media} />
          <span className="post-date">{formatDate(date)}</span>
        </div>

        <div className="post-content d-flex flex-column">

          <h3 className="post-title"  dangerouslySetInnerHTML={{ __html: formatExcerpt(title) }}></h3>

          <div className="meta d-flex align-items-center">
            <div className="d-flex align-items-center">
              <i className="bi bi-person"></i> <span className="ps-2">{author}</span>
            </div>
            <span className="px-3 text-black-50">/</span>
            <div className="d-flex align-items-center">
              <i className="bi bi-folder2"></i> <span className="ps-2">
              {categories.map((item, index) => (
              <React.Fragment key={item.slug}>
                <Link to={`/category/${item.slug}`}>{item.name}</Link>
                {index < categories.length - 1 && ', '}
              </React.Fragment>
            ))}
              </span>
            </div>
          </div>

          <p dangerouslySetInnerHTML={{ __html: formatExcerpt(excerpt) }}>
            
          </p>

          <hr />
          <Link to={`/${slug}`}target="_blank" rel="noopener noreferrer">
            Show More
          </Link>
        </div>

      </article>
    </div>
  )
}


