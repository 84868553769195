import React, { useState, useEffect } from 'react';
import ShowImage from './ShowImage';
import { formatDate } from '../utils/dateUtils';
import HomePageLoader from './HomePageLoader';
import { Link } from 'react-router-dom';
import apiClient from '../api/apiClient';
import { API_ENDPOINTS } from '../config/apiEndpoints';

export default function HomePostSection({archieve_id='' }) {

    const [posts, setPosts] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);

    let fields = 'id, slug, title, excerpt, featured_media, link, categories, date, author_details';
       useEffect(() => {
        const fetchPosts = async () => {
            setPageLoader(true);
            try {
              const response = await apiClient.get(API_ENDPOINTS.POSTS, {
                params: { page: 1, per_page: 13,_fields: `${fields}`,categories_exclude: 435},
              });
              console.log("Posts:", response.data);
              setPosts(response.data);
              setPageLoader(false);
            } catch (error) {
              console.error("Error fetching users:", error);
            }


        };

        fetchPosts();
    }, [fields]);


    const formatExcerpt = (excerpt) => {
        return excerpt.replace(/\[&hellip;\]|\[…\]/g, '...');
      };
    return (
        <>
        {posts[0] && (
        <section id="trending-category" className="trending-category section">

      <div className="container" data-aos="fade-up" data-aos-delay="100" key={`post-${posts[0].id}`}>

        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-4">
            
                <>
              <div className="post-entry lg">
              <Link to={`/${posts[0].slug}`}  ><ShowImage media_id={posts[0].featured_media} /></Link>
                <div className="post-meta"><span className="date">{posts[0].categories[0].name}</span>
                     <span className="mx-1">•</span> 
                     <span>{formatDate(posts[0].date)}</span></div>
                <h2><Link to={`/${posts[0].slug}`}  >{posts[0].title.rendered} </Link></h2>
                <p className="mb-4 d-block" dangerouslySetInnerHTML={{ __html: formatExcerpt(posts[0].excerpt.rendered) }}></p>

                <div className="d-flex align-items-center author">
                  <div className="photo"><img src={posts[0].author_details.avatar} alt="" className="img-fluid"/></div>
                  <div className="name">
                    <h3 className="m-0 p-0">{posts[0].author_details.name}</h3>
                  </div>
                </div>
              </div> 
              </>
              

            </div>

            <div className="col-lg-8">
              <div className="row g-5">

              {[...Array(2)].map((_, colIndex) => (
                
                    <div className="col-lg-4 border-start custom-border" key={`col-${colIndex}`}>
                        {posts.slice(colIndex * 3+1, colIndex * 3 + 4).map((post, index) => (
                        <div className="post-entry" key={`post-thumb-${post.id}`}>
                            <Link to={`/${post.slug}`}  >

                            <ShowImage media_id={post.featured_media} />
                            </Link>
                            <div className="post-meta">
                            <span className="date">{post.categories[0].name}</span>
                            <span className="mx-1">•</span>
                            <span>{formatDate(post.date)}</span>
                            </div>
                            <h2>
                            <Link to={`/${post.slug}`}  >{post.title.rendered} </Link>
                            </h2>
                        </div>
                        ))}
                    </div>
                    ))}



                <div className="col-lg-4">

                  <div className="trending">
                    <h3>Trending</h3>
                    <ul className="trending-post">

                    {posts.slice(8, 13).map((post, index) => (
                        <li key={`posts-trend-${post.id}`}>
                            <Link to={`/${post.slug}`}  >
                                <span className="number">1</span>
                                <h3>{post.title.rendered}</h3>
                                <span className="author">{post.author_details.name}</span>
                            </Link>
                        </li>

                        ))}

                    </ul>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </section>
)}
{pageLoader && <HomePageLoader /> }
</>
    );
};