import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ShowImage from './ShowImage';
import { Helmet } from 'react-helmet-async';
import SidebarSection from './SidebarSection';
import { formatDate } from '../utils/dateUtils';
import apiClient from '../api/apiClient';
import { API_ENDPOINTS } from '../config/apiEndpoints';
import SinglePostLoader from './SinglePostLoader';



export default function SinglePost({ setProgressBar, post_type = 'posts' }) {
    let { slug } = useParams();
    const [post, setPost] = useState('');
    const [pageLoader, setPageLoader] = useState(false);

    useEffect(() => {
        const showPost = async () => {
            try {
                setPageLoader(true);
                let fields = 'id,date,title,content,featured_media,meta,author_details'
                const response = await apiClient.get(API_ENDPOINTS.POSTS, {
                    params: { _fields: `${fields}`,slug: `${slug}`},
                  });

                const data = response.data;

                if (data.length > 0) {
                    setPost(data);
                    setPageLoader(false);
                }

                setProgressBar(100);
            } catch (error) {
                console.error('Error fetching single post/blogs:', error);
            }
        };

        showPost();
    }, [slug, post_type, setProgressBar]);

    return (
        <>
            {post && (
                <>
                    <Helmet>
                        <title>{post[0].meta.rank_math_title}</title>
                        <meta name="description" content={post[0].meta.rank_math_description} />
                        <meta property="og:title" content={post[0].meta.rank_math_title} />
                    </Helmet>

                    <div className="page-title">
                        <div className="container d-lg-flex justify-content-between align-items-center">
                            <h1 className="mb-2 mb-lg-0">Single</h1>
                            <nav className="breadcrumbs">
                                <ol>
                                    <li><a href="index.html">Home</a></li>
                                    <li className="current">Single Post</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <section id="blog-details" className="blog-details section">
                                    <div className="container">
                                        <article className="article">
                                            <div className="post-img">
                                                <ShowImage media_id={post[0].featured_media} size="full" />
                                            </div>
                                            <h2 className="title">{post[0].title.rendered}</h2>
                                            <div className="meta-top">
                                                <ul>
                                                    <li className="d-flex align-items-center">
                                                        <i className="bi bi-person"></i> 
                                                        <a href="blog-details.html">{post[0].author_details.name}</a>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <i className="bi bi-clock"></i> {formatDate(post[0].date)}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="content" dangerouslySetInnerHTML={{ __html: post[0].content.rendered }}></div>

                                            <div className="meta-bottom">
                                                <i className="bi bi-folder"></i>
                                                <ul className="cats">
                                                    <li><a href="/">Business</a></li>
                                                </ul>

                                                <i className="bi bi-tags"></i>
                                                <ul className="tags">
                                                    <li><a href="/">Creative</a></li>
                                                    <li><a href="/">Tips</a></li>
                                                    <li><a href="/">Marketing</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </section>
                            </div>

                            <SidebarSection />
                        </div>
                    </div>
                </>
            )}
        {pageLoader && <SinglePostLoader /> }
        </>
    );
}
