import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import apiClient from '../api/apiClient';
import { API_ENDPOINTS } from '../config/apiEndpoints';


const TagCloud = () => {
  const [tags, setTags] = useState([]);

  // Function to fetch tags from the WordPress REST API
  const fetchTags = async () => {
    try {
      let allTags = [];
      let page = 1;
      let totalPages = 1;



      do {
        const response = await apiClient.get(API_ENDPOINTS.TAGS, {
          params: { per_page: 100,page: `${page}`},
        });
        // const data = await response.json();
        allTags = [...allTags, ...response.data];

        // Get total number of pages from the headers
        totalPages = parseInt(response.headers.get('X-WP-TotalPages'), 10);
        page++;
      } while (page <= totalPages);

      // Filter tags with count greater than 15
      const filteredTags = allTags.filter(tag => tag.count > 10);
      setTags(filteredTags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  // Fetch tags when the component mounts
  useEffect(() => {
    fetchTags();
  }, []);

  // Function to determine the font size based on the count of posts for a tag
  // const getTagFontSize = (count) => {
  //   const minSize = 12; // Min font size for tags
  //   const maxSize = 18; // Max font size for tags
  //   const maxCount = Math.max(...tags.map(tag => tag.count)); // Highest count of any tag
  //   return `${Math.floor(minSize + (count / maxCount) * (maxSize - minSize))}px`;
  // };

  return (<>

<div className="tags-widget widget-item">

  <h3 className="widget-title">Tags</h3>
    <ul>
      {tags.length === 0 ? (
        <Spinner />
      ) : (
        tags.map(tag => (
          <li key={`tag-${tag.id}`}> 
            <Link key={tag.id} to={`/tag/${tag.slug}`} >
              {tag.name} ({tag.count})
            </Link>
          </li>
        ))
      )} 
      </ul>
    </div></>
  );
};

export default TagCloud;
