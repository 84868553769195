import React, { useState, useEffect } from 'react';
import PostLists from './PostLists';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from './Spinner';
import PostLoader from './PostLoader';
import SidebarSection from './SidebarSection';
import apiClient from '../api/apiClient';
import { API_ENDPOINTS } from '../config/apiEndpoints';


const Posts = ({ setProgressBar, post_type = 'posts', setPostIDSinglePage, archieve_id = '', archive_type = '' }) => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadedRecords, setLoadedRecords] = useState(0);
    const [showPostLoader, setShowPostLoader] = useState(false);

    // Reset posts and page when category changes
    useEffect(() => {
        setPosts([]);
        setPage(1);
        setTotalRecords(0);
        setLoadedRecords(0);
    }, [archieve_id]);



    const fields = 'id,slug,title,excerpt,featured_media,link,categories,featured_image_details,date,author_details';

    // Fetch posts whenever the page or archive changes
    useEffect(() => {
        const fetchPosts = async () => {
            setProgressBar(10);
            try {
                setShowPostLoader(true);
                const response = await apiClient.get(API_ENDPOINTS.POSTS, {
                  params: { 
                    page: 1, 
                    limit: 10,
                    _fields: `${fields}`,
                    ...(archive_type === "tag" 
                        ? { tags: `${archieve_id}` } 
                        : { categories: `${archieve_id}`  })},
                });
                setProgressBar(30);
                console.log("Posts:", response.data);
                // setPosts(response.data);
                setProgressBar(70);
                const totalPosts = parseInt(response.headers.get('X-WP-Total'), 10);
                setTotalRecords(totalPosts);
                setLoadedRecords((prevLoadedRecords) => prevLoadedRecords + response.data.length);
                setPosts((prevPosts) => [...prevPosts, ...response.data]);
                setShowPostLoader(false);
              } catch (error) {
                console.error("Error fetching users:", error);
              }

        };

        fetchPosts();
    }, [page, archieve_id]);

    // Load more posts when the user scrolls
    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <>
            <InfiniteScroll
                dataLength={posts.length}
                next={fetchMoreData}
                hasMore={loadedRecords < totalRecords}
                loader={<Spinner />}
            >
                <div className="page-title position-relative">
                    <div className="container d-lg-flex justify-content-between align-items-center">
                        <h1 className="mb-2 mb-lg-0">Category</h1>
                        <nav className="breadcrumbs">
                            <ol>
                                <li><a href="index.html">Home</a></li>
                                <li className="current">Categories</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <section id="blog-posts" className="blog-posts section">
                                <div className="container">
                                    <div className="row gy-4">
                                        {posts.map((post) => (
                                            
                                            <PostLists
                                                title={post.title.rendered}
                                                slug={post.slug}
                                                post_id={post.id}
                                                excerpt={post.excerpt.rendered}
                                                media={post.featured_media}
                                                setPostIDSinglePage={setPostIDSinglePage}
                                                post_type={post_type}
                                                categories={post.categories}
                                                featured_image_details={post.featured_image_details}
                                                date={post.date}
                                                author={post.author_details.name}
                                            />
                                        ))}
{setProgressBar(100)}
                                        {showPostLoader && <PostLoader loader={9} />}
                                    </div>
                                </div>
                            </section>
                        </div>
                        <SidebarSection />
                    </div>
                </div>
            </InfiniteScroll>
        </>
    );
};

export default Posts;
