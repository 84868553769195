import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/dateUtils';

const credentials = btoa('admin:Alankar@123#');
function RecentPosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Use `_embed` to include featured media (thumbnail image) in the response
        const response = await fetch(`http://localhost/sagas_react/wp-json/wp/v2/posts?per_page=5&_embed`, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${credentials}`,
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

 
  if (loading) {
    return <p>Loading recent posts...</p>;
  }

  return (
    <div className="recent-posts-widget widget-item">

    <h3 className="widget-title">Recent Posts</h3>
        {posts.map((post) => {
          // Check if the post has a featured media and retrieve its thumbnail URL
          const thumbnailUrl = post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.thumbnail?.source_url || 'https://via.placeholder.com/150x100';

          return (

            <div className="post-item" key={post.id} >
              <img 
              src={thumbnailUrl}
              alt={post.title.rendered}
              className="flex-shrink-0" />
              <div>
                <h4><Link to={`/${post.slug}`}target="_blank" rel="noopener noreferrer">
                    {post.title.rendered}
                  </Link></h4>
                <time dateTime="2020-01-01">{formatDate(post.date)}</time>
              </div>
            </div>
            

          );
        })}
      
    </div>
  );
}




export default RecentPosts;