import React from 'react'

const  HomePageLoader =() => {
  return (
    <section id="trending-category" className="trending-category section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="post-entry lg">
              <div className="placeholder-img img-fluid"></div>
              <div className="placeholder-meta"></div>
              <h2 className="placeholder-text"> Lorem Ipsum</h2>
              <p className="placeholder-text mb-4 d-block"></p>
              <div className="d-flex align-items-center author">
                <div className="placeholder-author-img img-fluid"></div>
                <div className="placeholder-text-sm"></div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="row g-5">
              {[...Array(2)].map((_, index) => (
                <div className="col-lg-4 border-start custom-border" key={index}>
                  {[...Array(3)].map((_, i) => (
                    <div className="post-entry" key={i}>
                      <div className="placeholder-img img-fluid"></div>
                      <div className="placeholder-meta"></div>
                      <h2 className="placeholder-text"> Lorem Ipsum</h2>
                    </div>
                  ))}
                </div>
              ))}

              <div className="col-lg-4">
                <div className="trending">
                  <h3>Trending</h3>
                  <ul className="trending-post">
                    {[...Array(5)].map((_, i) => (
                      <li key={i}>
                        <div className="d-flex align-items-center">
                          <span className="placeholder-number"></span>
                          <div className="placeholder-text"></div>
                        </div>
                        <span className="placeholder-author"></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomePageLoader ;