// src/api/apiClient.js
import axios from "axios";

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_1_URL, // Set your base URL in .env
  timeout: 10000, // Set a timeout for requests (10 seconds)
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Add authorization token if available

    const token = btoa(process.env.REACT_APP_REST_API_1_CRED);

    // const token = localStorage.getItem("authToken");

    if (token) {
      config.headers.Authorization = `Basic ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    if (error.response) {
      console.error("API Error:", error.response.data.message);
      // You can handle specific status codes here
      if (error.response.status === 401) {
        // Example: Redirect to login
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
