import React from 'react'
import HomePostSection from './HomePostSection'
import HomePostCategorySection from './HomePostCategorySection'

export default function Home() {
  return (
    <main className="main">

    <HomePostSection  />
    <HomePostCategorySection  archieve_id='435' archive_type='category'/>


    

  </main>
  )
}
