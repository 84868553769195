import React, {useState, useEffect} from 'react'
import Spinner from './Spinner';
import apiClient from '../api/apiClient';
import { API_ENDPOINTS } from '../config/apiEndpoints';


export default function ShowImage(props) {
    const [imageUrl, setImageUrl] = useState('');
    const [loadingImage, setLoadingImage] = useState(false);

    let {media_id,size ="medium"} = props ;



    useEffect(() => {
        const showMedia = async () => {
            try {
                setLoadingImage(true);
                let fields = 'id,source_url,media_details,alt_text,responsive_data'
                const response = await apiClient.get(`${API_ENDPOINTS.MEDIA}/${media_id}`, {
                    params: { _fields: `${fields}`},
                  });

                const data = response.data;

                setImageUrl(data);
                setLoadingImage(false)
            } catch (error) {
                console.error('Error fetching small image:', error);
            }
        };
        showMedia();
    },[media_id]);

    
    return (
        <>
            {loadingImage && <Spinner />}
            {imageUrl && imageUrl.media_details && imageUrl.media_details.sizes && imageUrl.media_details.sizes[size] && (
                <img 
                    src={imageUrl.media_details.sizes[size].source_url} 
                    loading="lazy"
                    className="img-fluid" 
                    alt={imageUrl.alt_text || 'Image'} 
                    srcSet={imageUrl.responsive_data.srcset}
                    sizes={imageUrl.responsive_data.sizes}
                    // width={imageUrl.media_details.sizes[size].width} // Set default width if width is not provided
                    // height={imageUrl.media_details.sizes[size].height} // Set default height if height is not provided
                    style={{ objectFit: 'cover' }} // Crop image to fit the container
                />
            )}
        </>
    );
}
