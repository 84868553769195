// import logo from './logo.svg';
// import './App.css';
import React, { useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavBar from "./Components/NavBar";
import Posts from "./Components/Posts";
import LoadingBar from "react-top-loading-bar";
import SinglePost from "./Components/SinglePost";
// import TagCloud from "./Components/TagClouds";
// import RecentPosts from "./Components/RecentPosts";
import categoryData from "./categoryData.json";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
// import CategoryChecker from "./Components/CategoryChecker";



function App() {

  const [progress, setProgress] = useState(0);




  const setProgressBar = useCallback((progress) => {
    setProgress(progress);
  }, []);


  return (
    <>
      <LoadingBar
        color="red"
        progress={progress} />
      <Router >
        <NavBar />

             
              <Routes>

                <Route exact path="/" element={<Home />} />
                <Route exact path="/:slug" element={<SinglePost setProgressBar={setProgressBar} />} />
                <Route exact path="/blog/:slug" element={<SinglePost setProgressBar={setProgressBar} post_type='blogs' />} />
                <Route exact path="/blog" element={<Posts setProgressBar={setProgressBar} post_type='blogs' archieve_id='999999999'  />} />
                
                
                
                
                
                {categoryData.map((data, index) => {
                  return (
                    <Route
                      exact
                      path={data.path}
                      key={data.id}
                      element={
                        <Posts
                          setProgressBar={setProgressBar}
                          post_type="posts"
                          category={data.slug}
                          archieve_id={data.id}
                          archive_type={data.archive_type}
                        />}
                    />
                  );
                })}

             </Routes>




                <Footer />
        
      </Router>
    </>
  );
}

export default App;