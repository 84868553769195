import React, { useState, useEffect } from 'react';
import ShowImage from './ShowImage';
import { formatDate } from '../utils/dateUtils';
import { Link} from 'react-router-dom';
// import HomePageCategoryLoader from './HomePageCategoryLoader';
import apiClient from '../api/apiClient';
import { API_ENDPOINTS } from '../config/apiEndpoints';


export default function HomePostCategorySection({archieve_id}) {

    const [posts, setPosts] = useState([]);

    let fields = 'id,slug,title,excerpt,featured_media,link,categories,date,author_details';

    useEffect(() => {
        const fetchPosts = async () => {
          // setPageLoader(true);
          try {
            const response = await apiClient.get(API_ENDPOINTS.POSTS, {
              params: { page: 1, per_page: 10,_fields: `${fields}`,categories: `${archieve_id}`},
            });
            console.log("Posts:", response.data);
            setPosts(response.data);
          } catch (error) {
            console.error("Error fetching users:", error);
          }


        };

        fetchPosts();
    }, [archieve_id,fields]);


    const formatExcerpt = (excerpt) => {
        return excerpt.replace(/\[&hellip;\]|\[…\]/g, '...');
      };
 
    return (
        <>
        {posts[0] && (
        <section id="culture-category" className="culture-category section">


        <div className="container section-title" data-aos="fade-up">
          <div className="section-title-container d-flex align-items-center justify-content-between">
            <h2>{posts[0].categories[0].name}</h2>
            <p><Link to={`/category/${posts[0].categories[0].slug}`}>See All {posts[0].categories[0].name}</Link></p>
          </div>
        </div>

<div className="container" data-aos="fade-up" data-aos-delay="100">

  <div className="row">
    <div className="col-md-9">
    
      <div className="d-lg-flex post-entry">
      <Link to={`/${posts[0].slug}`} className="me-4 thumbnail mb-4 mb-lg-0 d-inline-block" > 
        <ShowImage media_id={posts[0].featured_media} size ="full" />
        </Link>
        <div>
          <div className="post-meta">
            <span className="date">{posts[0].categories[0].name}</span>
          <span className="mx-1">•</span> <span>{formatDate(posts[0].date)}</span>
          </div>
          <h3> <Link to={`/${posts[0].slug}`}>{posts[0].title.rendered} </Link></h3>
          <p dangerouslySetInnerHTML={{ __html: formatExcerpt(posts[0].excerpt.rendered) }}></p>
          <div className="d-flex align-items-center author">
            <div className="photo"><img src={posts[0].author_details.avatar} alt="" className="img-fluid"/> </div>
            <div className="name">
              <h3 className="m-0 p-0">{posts[0].author_details.name}</h3>
            </div>
          </div>full
        </div>
      </div>
   

      <div className="row">
        <div className="col-lg-4">
          <div className="post-list border-bottom" key={`post-${posts[1].id}`}>
            <Link to={`/${posts[1].slug}`}><ShowImage media_id={posts[1].featured_media} /></Link>
              <div className="post-meta">
                <span className="date">
                  {posts[1].categories[0].name}
                </span>
                <span className="mx-1">•</span> <span>{formatDate(posts[1].date)}</span>
              </div>
              <h2 className="mb-2">
            <Link to={`/${posts[1].slug}`}  >

                {posts[1].title.rendered}
              </Link>
              </h2>
            <span className="author mb-3 d-block">{posts[1].author_details.name}</span>
            <p className="mb-4 d-block"  dangerouslySetInnerHTML={{ __html: formatExcerpt(posts[1].excerpt.rendered) }}></p>
          </div>

          <div className="post-list"  key={`post-${posts[2].id}`}>
            <div className="post-meta"><span className="date">{posts[2].categories[0].name}</span> <span className="mx-1">•</span> <span>{formatDate(posts[2].date)}</span></div>
            <h2 className="mb-2"><Link to={`/${posts[2].slug}`}>{posts[2].title.rendered}</Link></h2>
            <span className="author mb-3 d-block">{posts[2].author_details.name}</span>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="post-list"  key={`post-${posts[3].id}`}>
            <Link to={`/${posts[3].slug}`}><ShowImage media_id={posts[3].featured_media } size="full" /></Link>
            <div className="post-meta">
              <span className="date">
                {posts[3].categories[0].name}
              </span>
              <span className="mx-1">•</span> <span>{formatDate(posts[3].date)}</span>
            </div>
            <h2 className="mb-2"><Link to={`/${posts[3].slug}`}  >{posts[3].title.rendered}</Link></h2>
            <span className="author mb-3 d-block">{posts[3].author_details.name}</span>
            <p className="mb-4 d-block" dangerouslySetInnerHTML={{ __html: formatExcerpt(posts[3].excerpt.rendered) }}></p>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-3">
      {posts.slice(4, 10).map((post, index) => (

        <div className="post-list border-bottom" key={`post-${post.id}`}>
          <div className="post-meta">
            <span className="date">{post.categories[0].name}</span> 
            <span className="mx-1">•</span> 
            <span>{formatDate(post.date)}</span>
          </div>
          <h2 className="mb-2"><Link to={`/${post.slug}`}>{post.title.rendered}</Link></h2>
          <span className="author mb-3 d-block">{post.author_details.name}</span>
        </div>
        ))}


    </div>
  </div>

</div>

</section>



) }
{/* {pageLoader && <HomePageCategoryLoader /> } */}
</>


);
};


