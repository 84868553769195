import React from 'react'
import RecentPosts from './RecentPosts'
import TagCloud from './TagClouds'

export default function SidebarSection() {
  return (
    <>
    <div className="col-lg-4 sidebar">

    <div className="widgets-container">

      {/* <!-- Blog Author Widget --> */}
      <div className="blog-author-widget widget-item">

        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center w-100">
            <img src="assets/img/blog/blog-author.jpg" className="rounded-circle flex-shrink-0" alt=""/>
            <div>
              <h4>Jane Smith</h4>
              <div className="social-links">
                <a href="https://x.com/"><i className="bi bi-twitter-x"></i></a>
                <a href="https://facebook.com/"><i className="bi bi-facebook"></i></a>
                <a href="https://instagram.com/"><i className="biu bi-instagram"></i></a>
                <a href="https://instagram.com/"><i className="biu bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <p>
            Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum sed possimus accusantium. Quas repellat voluptatem officia numquam sint aspernatur voluptas. Esse et accusantium ut unde voluptas.
          </p>

        </div>

      </div>
      {/* <!--/Blog Author Widget --> */}

      {/* <!-- Search Widget --> */}
      <div className="search-widget widget-item">

        <h3 className="widget-title">Search</h3>
        <form action="">
          <input type="text" />
          <button type="submit" title="Search"><i className="bi bi-search"></i></button>
        </form>

      </div>
      {/* <!--/Search Widget --> */}
      {/* <!-- Recent Posts Widget --> */}
      <RecentPosts /> 
      {/* <!--/Recent Posts Widget --> */}


      {/* <!-- Tags Widget --> */}
       <TagCloud /> 
      {/* <!--/Tags Widget --> */}

    </div>

  </div>
  </>
  )
}
